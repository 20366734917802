import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {addClass} from "@elements/dom-utils";

export function init() {
    onFind('.js-image-slider', function (baseElement) {
        const imageSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: 1,
                draggable: true,
            },
        });

        addClass('initialized', baseElement)
    });
}